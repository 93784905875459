export default {
  sections: object => {
    return [
      {
        editable: true,
        title: 'АДРЕС',
        params: [
          {
            title: '',
            key: 'address_district_id'
          },
          {
            title: 'ПОЯСНЕНИЯ К АДРЕСУ',
            key: 'address_info'
          },
          {
            map: true,
            title: 'НА КАРТЕ',
            key: 'geo_polygon'
          }
        ]
      },
      {
        editable: true,
        title: 'Общие сведения',
        params: [
          {
            title: 'ТИП НАРУШЕНИЯ',
            key: 'violation_type_id'
          },
          {
            title: 'КАДАСТРОВЫЙ НОМЕР ЗЕМЕЛЬНОГО УЧАСТКА',
            key: 'place_cadastral_number'
          },
          {
            title: 'ВИД РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ',
            key: 'permitted_use_kind_name'
          },
          {
            title: 'ВИД РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ, ОПИСАНИЕ',
            key: 'permitted_use_kind_description'
          },
          {
            title: 'ТИП ОБЪЕКТА И ВИД ОБЪЕКТА',
            key: 'object_type_name'
          },
          {
            title: 'ДОПОЛНИТЕЛЬНОЕ ОПИСАНИЕ ТИПА ОБЪЕКТА И ВИДА ОБЪЕКТА',
            key: 'object_type_description'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_nto_private_place_permitted_use'
          },
          {
            title: 'Площадь земельного участка, кв.м',
            key: 'land_area'
          },
          {
            title: 'Количество машино-мест, всего',
            key: 'parking_place_total_cnt'
          },
          {
            title:
              'Количество машино-мест, в т.ч. для парковки специальных автотранспортных средств инвалидов',
            key: 'parking_place_with_disabled_cnt'
          }
        ]
      },

      {
        title: 'ВЛАДЕЛЕЦ ОБЪЕКТА',
        params: [
          {
            title: 'ИНН',
            key: 'owner_object_inn'
          },
          {
            title: 'НАИМЕНОВАНИЕ',
            key: 'owner_object_name'
          },
          {
            title: 'СТАТУС',
            key: 'owner_object_status_name'
          },
          {
            _if: object.status_id === 10,
            title: 'ОРГАНИЗАЦИОННО-ПРАВОВАЯ ФОРМА',
            key: 'owner_object_opf_name'
          },
          {
            title: 'ДОЛЖНОСТНОЕ ЛИЦО ВЛАДЕЛЬЦА ОБЪЕКТА',
            key: 'owner_object_official'
          }
        ]
      },
      {
        title: 'ВЛАДЕЛЕЦ ЗЕМЕЛЬНОГО УЧАСТКА',
        params: [
          {
            title: 'ИНН',
            key: 'owner_place_inn'
          },
          {
            title: 'НАИМЕНОВАНИЕ',
            key: 'owner_place_name'
          },
          {
            title: 'СТАТУС',
            key: 'owner_place_status_name'
          },
          {
            _if: object.status_id === 10,
            title: 'ОРГАНИЗАЦИОННО-ПРАВОВАЯ ФОРМА',
            key: 'owner_place_opf_name'
          },
          {
            title: 'ДОЛЖНОСТНОЕ ЛИЦО ВЛАДЕЛЬЦА ЗЕМЕЛЬНОГО УЧАСТКА',
            key: 'owner_place_official'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_nto_private_place_confirming_ownership'
          }
        ]
      },
      {
        title:
          'РЕКВИЗИТЫ АКТА ПРОВЕРКИ, УСТАНАВЛИВАЮЩЕГО ФАКТ ВЫЯВЛЕНИЯ НЕЗАКОННОГО ОБЪЕКТА',
        params: [
          {
            title: '№',
            key: 'audit_doc_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'audit_doc_date'
          },
          {
            title: 'ДАТА ВЫЯВЛЕНИЯ',
            key: 'audit_doc_date'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_audit_doc_files'
          },
          {
            title: 'ФОТОГРАФИИ ОБЪЕКТА',
            key: 'file_audit_doc_photos'
          },
          {
            title:
              'ОТМЕТКА О ФУНКЦИОНИРОВАНИИ ТОРГОВОГО ОБЪЕКТА, ОБЪЕКТА ПО ОКАЗАНИЮ УСЛУГ (ФУНКЦИОНИРУЕТ)',
            key: 'object_functioning_mark_name'
          }
        ]
      },
      {
        editable: true,
        title: 'СОБЛЮДЕНИЕ ПРАВИЛ БЛАГОУСТРОЙСТВА',
        params: [
          {
            title: 'СОБЛЮДЕНИЕ ПРАВИЛ БЛАГОУСТРОЙСТВА',
            key: 'beautification_rules_name'
          },
          {
            title:
              'Соблюдение требования по не размещению объекта приводящее к сокращению свободного для пешеходного движения тротуара до ширины менее 3 метров',
            key: 'compliance_crosswalk_requirements'
          },
          {
            title:
              'Соблюдение требования по не размещению объекта на площадках (детских, отдыха, спортивных), на газонах, цветниках, а также на объектах озеленения',
            key: 'compliance_fields_requirements'
          },
          {
            title:
              'Соблюдение вида разрешенного использования земельного участка при размещении объекта',
            key: 'compliance_kind_requirements'
          },
          {
            title:
              'Соблюдение требования по не размещению объекта на придомовой территории',
            key: 'compliance_house_territory_requirements'
          },
          {
            title:
              'Соблюдение требования о размещении объекта далее 15 метров от фасадов и окон зданий',
            key: 'compliance_facade_requirements'
          },
          {
            title:
              'Соблюдение требования о не размещении объекта в границах объектов культурного наследия',
            key: 'compliance_cultural_heritage_requirements'
          }
        ]
      },
      {
        editable: true,
        title: 'ПРОЧЕЕ',
        params: [
          {
            title: 'МЕСТО ПОДКЛЮЧЕНИЯ К ЭЛЕКТРИЧЕСКИМ СЕТЯМ',
            key: 'place_electric_networks_name'
          },
          {
            title: 'МЕСТО ПОДКЛЮЧЕНИЯ К ЭЛЕКТРИЧЕСКИМ СЕТЯМ,АДРЕС ЗДАНИЯ',
            key: 'place_electric_networks_address'
          },
          {
            title: 'СТАТУС ПОДКЛЮЧЕНИЯ К ЭЛЕКТРИЧЕСКИМ СЕТЯМ',
            key: 'place_electric_networks_status_name'
          },
          {
            title:
              'СТАТУС НАПРАВЛЕНИЯ ПИСЬМА В ЭНЕРГОСНАБЖАЮЩУЮ ОРГАНИЗАЦИЮ О САМОВОЛЬНОМ ПОДКЛЮЧЕНИИ К ЭЛЕКТРИЧЕСКИМ СЕТЯМ',
            key: 'place_electric_networks_mail_status_name'
          },
          {
            title: 'ОТМЕТКА О РАЗМЕЩЕНИИ НА ПЕРВОЙ ЛИНИИ УЛИЦЫ',
            key: 'place_first_line_street_name'
          },
          {
            title:
              'СТАТУС НАПРАВЛЕНИЯ ПИСЬМА В ГОСПОЖНАДЗОР МЧС РОССИИ ПО ПЕРМСКОМУ КРАЮ',
            key: 'mcs_mail_status_name'
          },
          {
            title: 'МЕРОПРИЯТИЯ, ПРОВОДИМЫЕ МЧС РОССИИ ПО ПЕРМСКОМУ КРАЮ',
            key: 'mcs_actions_name'
          },
          {
            title: 'ДАТА ВЫДАЧА ПРЕДОСТЕРЕЖЕНИЯ ОТ МЧС',
            key: 'date_of_caution'
          },
          {
            title: 'ПРЕДОСТЕРЕЖЕНИЕ ОТ МЧС, КОПИИ ДОКУМЕНТОВ',
            files: true,
            key: 'file_nto_private_place_warning'
          },
          {
            title: 'ДАТА ПРЕДОСТАВЛЕНИЯ МАТЕРИАЛОВ НА ЗАСЕДАНИИ ПО ЧС',
            key: 'meeting_submission_date'
          },
          {
            title: 'МАТЕРИАЛЫ НА ЗАСЕДАНИИ ПО ЧС, КОПИИ ДОКУМЕНТОВ',
            key: 'file_nto_private_place_materials_emergency_meeting',
            files: true
          }
        ]
      },
      {
        editable: true,
        title: 'Добровольный демонтаж',
        params: [
          {
            title: 'ДЕМОНТИРОВАН',
            key: 'mark_dismantling'
          },
          {
            title: 'ДАТА ДЕМОНТАЖА',
            key: 'dismantling_date'
          },
          {
            title: 'ПРИМЕЧАНИЕ',
            key: 'description'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_nto_private_place_voluntary_dismantling_docs'
          },
          {
            title: 'ФОТОГРАФИИ ОБЪЕКТА',
            key: 'file_nto_private_place_voluntary_dismantling_photos'
          }
        ]
      },
      {
        editable: true,
        title: 'ПРИНУДИТЕЛЬНЫЙ ДЕМОНТАЖ (ПО РЕШЕНИЮ КЧС)',
        params: [
          {
            title: 'ДАТА РЕШЕНИЯ КЧС О ДЕМОНТАЖЕ ОБЪЕКТА',
            key: 'dismantling_decision_date'
          },
          {
            title: 'НОМЕР РЕШЕНИЯ КЧС О ДЕМОНТАЖЕ ОБЪЕКТА',
            key: 'dismantling_decision_number'
          },
          {
            files: true,
            title: 'РЕШЕНИЕ КЧС ДЕМОНТАЖЕ ОБЪЕКТОВ, КОПИИ ДОКУМЕНТОВ',
            key: 'file_nto_private_place_coes_decisions'
          },
          {
            title: 'ДАТА УВЕДОМЛЕНИЯ О РЕШЕНИИ КЧС И ТРЕБОВАНИИ О ДЕМОНТАЖЕ',
            key: 'coes_notification_decision_dismatle_date'
          },
          {
            files: true,
            title: 'РЕШЕНИИ КЧС, КОПИИ ДОКУМЕНТОВ',
            key: 'file_nto_private_place_decision_dismantle_objects'
          }
        ]
      },
      {
        editable: true,
        title: 'ПРИНУДИТЕЛЬНЫЙ ДЕМОНТАЖ (ПО РЕШЕНИЮ СУДЕБНЫХ ОРГАНОВ)',
        params: [
          {
            title: 'ДАТА ПОДАЧИ ИСКА НА ВЛАДЕЛЬЦА ОБЪЕКТА',
            key: 'date_claim_object_owner'
          },
          {
            files: true,
            title: 'ИСК НА ВЛАДЕЛЬЦА ОБЪЕКТ, КОПИИ ДОКУМЕНТОВ',
            key: 'file_nto_private_place_claim_object_owner'
          },
          {
            title: 'ДАТА РЕШЕНИЯ СУДЕБНЫХ ОРГАНОВ',
            key: 'judicial_decision_date'
          },
          {
            title: 'ДАТА ОКОНЧАНИЯ СРОКА ОБЖАЛОВАНИЯ',
            key: 'appeal_deadline_date'
          },
          {
            files: true,
            title: 'РЕШЕНИЕ СУДЕБНЫХ ОРГАНОВ, КОПИИ ДОКУМЕНТОВ',
            key: 'file_nto_private_place_judicial_decision'
          },
          {
            title: 'ДАТА ОКОНЧАНИЯ СРОКА ПРИНУДИТЕЛЬНОГО ДЕМОНТАЖА',
            key: 'expiration_date'
          },
          {
            title: 'ДАТА РАЗМЕЩЕНИЯ УВЕДОМЛЕНИЯ НА ОБЪЕКТЕ',
            key: 'nto_notification_date'
          },
          {
            photos: true,
            title: 'РАЗМЕЩЕНИЕ УВЕДОМЛЕНИЯ НА ОБЪЕКТЕ, ФОТОГРАФИИ',
            key: 'file_nto_private_place_nto_notification'
          },
          {
            title: 'ОТМЕТКА О ПРИНУДИТЕЛЬНОМ ДЕМОНТАЖЕ',
            key: 'forced_dismantling_mark'
          },
          {
            title: 'ДАТА ПРИНУДИТЕЛЬНОГО ДЕМОНТАЖА',
            key: 'forced_dismantling_date'
          },
          {
            title: 'ПРИНУДИТЕЛЬНЫЙ ДЕМОНТАЖ, ФОТОГРАФИИ',
            key: 'file_nto_private_place_forced_dismantling_photos'
          }
        ]
      },
      {
        title: 'СВЕДЕНИЯ О ДОГОВОРЕ АРЕНДЫ',
        params: [
          {
            title: '№',
            key: 'agreement_number'
          },
          {
            title: 'ДАТА',
            key: 'agreement_date'
          },
          {
            title: 'Дата окончания',
            key: 'agreement_end_date'
          },

          {
            title: 'Информация о продлении',
            key: 'agreement_extension_date'
          },
          {
            title: 'Сведения об арендаторе',
            key: 'information_about_tenant'
          },
          {
            title: 'Сведения о нарушениях',
            key: 'information_about_violations'
          },
          {
            title: 'Вид разрешенного использования земельного участка',
            key: 'agreement_land_usage_type_id'
          }
        ]
      }
      //{ title: "", params: [{ title: "", key: "" }] },
    ];
  }
};
